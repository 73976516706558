import React from 'react';
import AdminNav from './AdminNav';

const Admin = (props) => {
        
    return(
        <div className="cont_adm">
            <div className="center">
                <AdminNav doLogout={props.doSignOut} page={props.page}/>
                {props.children}
            </div>
        </div>
    );
    
}

export default Admin;