import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';


const withAuthentication = Component => {
	class WithAuthentication extends React.Component {
		constructor(props) {
			super(props);

			/*	this.state = {
					authUser: true
				}*/

			this.state = {
				authUser: JSON.parse(localStorage.getItem('authUser')),
			};
		}

		componentDidMount() {
			/*  this.listener = this.props.firebase.auth.onAuthStateChanged(
				authUser => {
				  authUser
					? this.setState({ authUser: true })
					: this.setState({ authUser: null });
				},
			  );
		
	*/
			this.listener = this.props.firebase.auth.onAuthStateChanged(
				authUser => {
					if (authUser) {
						this.setState(state => ({ authUser: authUser }), localStorage.setItem('authUser', JSON.stringify(authUser)));
					} else {
						this.setState(state => ({ authUser: null }),localStorage.removeItem('authUser'));
					}
				},
			);
		}



		componentWillUnmount() {
			this.listener();
		}

		render() {
			return (
				<AuthUserContext.Provider >
					<Component {...this.props} authUser={this.state.authUser} />
				</AuthUserContext.Provider>
			);
		}
	}

	return withFirebase(WithAuthentication);
};

export default withAuthentication;