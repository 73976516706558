import React from 'react';
import { withAuthentication } from '../../Session';
import AdminForm from '../components/AdminForm';

const INTITIAL_STATE_CLIENTE = { nome: '', telefones: [{desc:"Telefone 1", value: ''}] };

class AdminAdd extends React.Component{

	constructor(props){
		super(props);
		this.state = { cliente:{nome: '', telefones: [{desc:"Telefone 1", value: ''}]}, loading:false};

		this.handleSubmit 	 	 = this.handleSubmit.bind(this);
		this.addNovoTelefone 	 = this.addNovoTelefone.bind(this);
		this.removerTelefone 	 = this.removerTelefone.bind(this);
		this.handleTelefoneInput = this.handleTelefoneInput.bind(this);
		this.hadleNomeInput		 = this.handleNomeInput.bind(this);

		this.pFunctions = { doLogout:this.props.firebase.doSignOut
						   ,handleSubmit:this.handleSubmit
						   ,addNovoTelefone:this.addNovoTelefone
						   ,removerTelefone:this.removerTelefone
						   ,handleTelefoneInput:this.handleTelefoneInput
						   ,hadleNomeInput:this.hadleNomeInput
		}
	}


	addNovoTelefone(e){
		const tamanho 	   = this.state.cliente.telefones.length + 1;
		const novoTelefone = {desc:"Telefone " + tamanho.toString(), value: ''};
		
		let clientesUpdated = this.state.cliente;
		clientesUpdated.telefones =  [...clientesUpdated.telefones, novoTelefone]

		//Concatena o novo telefone ao array this.state.telefones;
		this.setState(state => ({
			cliente: clientesUpdated
		}));

		e.preventDefault();
	}

	removerTelefone(e){
		if(this.state.cliente.telefones.length <= 1)
			return;
		
		const telefoneRemovido = this.state.cliente.telefones.pop();
		let clientesUpdated = this.state.cliente;
		clientesUpdated.telefones = this.state.cliente.telefones.filter(t => t.desc !== telefoneRemovido.desc)

		this.setState(state => ({
			cliente: clientesUpdated
		}));

		e.preventDefault();
	}

	handleSubmit(e){
		const novaRef = this.props.firebase.getClientes().push();
		
		let novoCliente = this.state.cliente;
		novoCliente.id = novaRef.getKey();

		this.setState(state => ({
			cliente:novoCliente
		}),() => {
			this.setState(state => ({
				loading:true
			}),() => {novaRef.set(this.state.cliente)
				.then( () => {
			
					let nCliente = this.props.firebase.getClientesMin().push();
					nCliente.set(
						{
							nome:this.state.cliente.nome,
							id:this.state.cliente.id,
							key:nCliente.key
						}
					);

					this.props.firebase.getClientesLastUpd().once('value', snapshot =>{
						let ultimaAtt = Number(snapshot.val());
						this.props.firebase.getClientesLastUpd().set(ultimaAtt+1);
					}).then(() => {
						alert('Cliente ' + this.state.cliente.nome  + ' adicionado com sucesso!');
						this.setState(state => ({
							cliente:INTITIAL_STATE_CLIENTE
						}))
					})
					
				})
	
				.catch(err => () => alert('Erro ao adicionar novo cliente!'))
				.finally(this.setState(state => ({ loading:false})));
			});
		} )

		

		e.preventDefault();
	}

	handleTelefoneInput(e, id){
		const newInput = this.state.cliente.telefones.slice();
		newInput[id].value = e.target.value;
		let clientesUpdated = this.state.cliente;
		
		clientesUpdated.telefones = newInput;

		this.setState(state => ({
			cliente: clientesUpdated
		}));
		
		e.preventDefault();
	}

	handleNomeInput(e){
		let clientesUpdated = this.state.cliente;
		clientesUpdated.nome = e.target.value
		this.setState({cliente:clientesUpdated});
		e.preventDefault();
	}

	handleClick = (e) =>{
		e.preventDefault();
	}

	render(){	
		return (
			<AdminForm functions={ this.pFunctions } cliente={this.state.cliente} page='add'/>
		);
	}
}

export default withAuthentication(AdminAdd);


