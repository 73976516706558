import React from 'react';
import './Login.css';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {withAuthentication} from '../Session/index';
import * as ROUTES from '../constants/routes';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


const INITIAL_STATE = {
	user:'', password:'', loading:false
}

class LoginComponent extends React.Component {

	constructor(props){
		super(props);
		if(props.authUser)
			this.props.history.push(ROUTES.ADMIN_HOME)
		this.state = { ...INITIAL_STATE };
		this.handleUserChange = this.handleUserChange.bind(this);
		this.handlePassChange = this.handlePassChange.bind(this);
		this.handleSubmit     = this.handleSubmit.bind(this);
	}

	handleSubmit(e){
		this.setState({loading:true})
		const email    = this.state.user + '@agendafo.com.br';
		const password = this.state.password;

		this.props.firebase.doLogin(email, password).then(() => {
			let history =  this.props.history;
			setTimeout( this.timeout = function(){ history.push(ROUTES.ADMIN_HOME) }, 1000);
			this.timeout=null;
		})
		.catch((err) => {
			clearTimeout(this.timeout);
			this.setState(state => ({loading:false}));
			if(err.code === 'auth/user-not-found'){
				alert('Usuário não existente.');
				return;
			}else if (err.code === 'auth/wrong-password'){
				alert('Senha incorreta.');
				return;
			}else {
				alert(err.message);
				return;
			}
		});;
		e.preventDefault();

	}

	componentWillUnmount = () =>{
		this.setState(INITIAL_STATE)
	}
	
	handleUserChange(e){
		this.setState({user: e.target.value});
	}

	handlePassChange(e){
		this.setState({password: e.target.value});
	}

	render() {
		return (
			<>
			{!this.state.loading?
			<div className="cont">
				<div className="header">
					Administração Agenda AFO
				</div>
				<div className="bd">
					<form onSubmit={this.handleSubmit}>
					<TextField 
							label="Usuário"
							id="usuario" 
							value={this.state.user} 
							style={{ width:"80%", marginLeft: "10%"}}
							margin="normal"
							onChange={this.handleUserChange}
							required
					/>
					<TextField 
							label="Senha"
							id="senha" 
							type="password"
							value={this.state.password}
							style={{ width:"80%", marginLeft: "10%", marginBottom:"10%"}}
							margin="normal"
							onChange={this.handlePassChange}
							required
					/>
						<Button variant="contained" 
								type="submit" 
								color="primary" 
								style={{marginRight:"10%",float:"right"}}
								className={this.props.button}>
							Enviar
						</Button>
					</form>
				</div>
			</div>
			:<CircularProgress className={this.props.progress} size={60} style={{margin:"0 auto", marginTop:"5%",display:"table"}}/>

			}
			</>
		);
	}

}

const Login = compose(withRouter, withAuthentication)(LoginComponent);

export default Login;