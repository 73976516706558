import React from 'react';
import '../Admin.css';
import { Link } from 'react-router-dom';
import Admin from '../components/Admin';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthentication } from '../../Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SvgIcon from '@material-ui/core/SvgIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminRight from '../components/AdminRight';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AlertDialog from '../components/AlertDialog'


const LinhaCliente = (props) => {

	let handleDelete = (e) => {
		props.handleDelete(props.cliente)
		e.preventDefault();
	}

	return (<TableRow key={props.cliente.id} hover={true}>
		<TableCell component="th" scope="row" style={{ width: "80%", fontSize: "12pt" }}>
			{props.cliente.nome}
		</TableCell>

		<TableCell style={{ fontSize: "12pt" }} >
			<Link to={
				{
					pathname: props.url + '/update/' + props.cliente.id,
					keyClienteMin: props.cliente.key
				}
			} >
				<SvgIcon className="icons">
					<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
				</SvgIcon>
			</Link>

			<SvgIcon onClick={handleDelete} color="secondary" className="icons">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			</SvgIcon>
		</TableCell>
	</TableRow>
	);
}


class BuscaInput extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			busca: ''
		}
	}

	handleBuscaInput = e => {
		this.setState({
			busca: e.target.value
		})
		if (e.target.value === '')
			this.props.handleBusca(null);
		e.preventDefault();
	}

	handleBusca = e => {
		this.props.handleBusca(this.state.busca)
		e.preventDefault();
	}

	Clear = e => {
		this.setState(state => ({
			busca: ''
		}), this.props.handleBusca(null))

		e.preventDefault();
	}

	render() {
		return (<form onSubmit={this.handleBusca} style={{ display: "inline" }}>

			<TextField
				label="Buscar"
				value={this.state.busca}
				style={{ width: "40%", marginBottom: "2%", marginRight: "2%", marginLeft: "20%", marginTop: "8px" }}
				margin="normal"
				onChange={this.handleBuscaInput}
			/>

			<Button type='submit'
				color="primary"
				variant="contained"
				style={{ marginTop: "20px", marginRight: "1%" }}>
				Buscar
					</Button>

			<Button onClick={this.Clear}
				color="secondary"
				variant="contained"
				disabled={!this.state.busca}
				style={{ marginTop: "20px" }}>
				Limpar Busca
					</Button>
		</form>

		);
	}
}


class AdminHome extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			clientes: [],
			loading: false,
			dialogOpened: false
		}
		this.clientesFiltrados = []
		this.clienteDeletando = {};
		window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
	}

	snapshotToArray = snapshot => {
		var returnArr = [];

		snapshot.forEach(function (childSnapshot) {
			var item = childSnapshot.val();
			item.key = childSnapshot.key;

			returnArr.push(item);
		});

		return returnArr;
	};

	componentDidMount() {
		if (!this.state.loading && this.state.clientes.length === 0) {
			this.setState({ loading: true });
			this.props.firebase.getClientesMin().once('value', snapshot => {

				this.setState(state => ({
					clientes: this.snapshotToArray(snapshot),
					clientesBkp: this.snapshotToArray(snapshot),
					loading: false
				}))
			});
		}
	}

	handleBusca = (busca) => {
		if (busca) {
			this.clientesFiltrados = []
			this.state.clientesBkp.forEach(cliente => cliente.nome.toUpperCase().includes(busca.toUpperCase()) ? this.clientesFiltrados.push(cliente) : null,

				this.setState(state => ({
					clientes: this.clientesFiltrados
				}), () => this.clientesFiltrados = null)
			);
		} else {
			this.setState(state => ({
				clientes: this.state.clientesBkp
			}), () => this.clientesFiltrados = null)
		}

	}

	handleDelete = (cliente) => {
		this.clienteDeletando = cliente;
		this.setState({
			dialogOpened:true
		})
	}

	handleCancelarDialog = () => {
		this.setState({
			dialogOpened: false
		});
		this.clienteDeletando = {};
	}

	handleConfirmarDialog = (cliente) => {
		this.props.firebase.getCliente(cliente.id).set(null).then(this.props.firebase.getClienteMin(cliente.key).set(null).then(() => {
			this.props.firebase.getClientesLastUpd().once('value', snapshot =>{
				let ultimaAtt = Number(snapshot.val());
				this.props.firebase.getClientesLastUpd().set(ultimaAtt+1);
			}).then(alert('O cliente "' + cliente.nome + '" foi removido com sucesso!'))
		}).catch( err => alert('Erro ao remover o cliente!' + err.toString())));
		
		console.log(cliente.id + '  ' + cliente.nome + ' ' + cliente.key)
		this.setState({
			dialogOpened: false
		});
		this.clienteDeletando = {};
	}

	render() {
		let linhas = this.state.clientes.map(pCliente => <LinhaCliente key={pCliente.id} cliente={pCliente} handleDelete={this.handleDelete} url={this.props.match.url}></LinhaCliente>);
		return (
			<Admin doSignOut={this.props.firebase.doSignOut} page='home'>
				<AlertDialog
					handleConfirmar={this.handleConfirmarDialog}
					handleCancelar={this.handleCancelarDialog}
					cliente={this.clienteDeletando}
					open={this.state.dialogOpened}
				/>
				<AdminRight>
					{!this.state.loading
						?
						<>
							<BuscaInput busca={this.state.busca} handleBuscaInput={this.handleBuscaInput} handleBusca={this.handleBusca} />
							<Table>
								<TableHead>
									<TableRow   >
										<TableCell style={{ width: "80%", fontSize: "13pt" }} >Nome</TableCell>
										<TableCell style={{ fontSize: "13pt" }}>Opções</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{linhas}
								</TableBody>
							</Table>
						</>
						:
						<CircularProgress className={this.props.progress} size={60} style={{ margin: "0 auto", display: "table" }} />

					}
				</AdminRight>
			</Admin>
		);
	}

}

export default compose(withAuthentication, withRouter)(AdminHome);