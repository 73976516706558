import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './login/Login';
import { AdminAdd, AdminHome, AdminImagens} from './admin';
import * as ROUTES from './constants/routes';
import { withAuthentication } from './Session';

import ClienteUpdate from './admin/home/ClienteUpdate';

const App = (props) => (
	<Router>
		<>
			<Route exact path={ROUTES.HOME}						component={Login} />
			<PrivateRoute exact path={ROUTES.ADMIN_HOME}		component={AdminHome}		authUser={props.authUser} />
			<PrivateRoute exact path={ROUTES.ADMIN_ADD}			component={AdminAdd}		authUser={props.authUser} />
			<PrivateRoute exact path={ROUTES.ADMIN_IMAGENS}		component={AdminImagens}	authUser={props.authUser} />
			<PrivateRoute exact path={ROUTES.ADMIN_UPDATE}		component={ClienteUpdate}	authUser={props.authUser} />
		</>
	</Router>
);

function PrivateRoute({ component: Component, authUser , ...rest }) {
	return (
		<Route
			{...rest}
			render={props =>
				authUser ? (
					<Component {... {"authUser": authUser}} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
}

export default withAuthentication(App);