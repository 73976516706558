import React from 'react';
import { withAuthentication } from '../../Session';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import AdminForm from '../components/AdminForm';

const INTITIAL_STATE_CLIENTE = { nome: '', telefones: [{ desc: "Telefone 1", value: '' }] };

class ClienteUpdate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			cliente: { ...INTITIAL_STATE_CLIENTE },
			loading: true
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.addNovoTelefone = this.addNovoTelefone.bind(this);
		this.removerTelefone = this.removerTelefone.bind(this);
		this.handleTelefoneInput = this.handleTelefoneInput.bind(this);
		this.hadleNomeInput = this.handleNomeInput.bind(this);

		this.pFunctions = {
			  doLogout: this.props.firebase.doSignOut
			, handleSubmit: this.handleSubmit
			, addNovoTelefone: this.addNovoTelefone
			, removerTelefone: this.removerTelefone
			, handleTelefoneInput: this.handleTelefoneInput
			, hadleNomeInput: this.hadleNomeInput
		}
	}

	componentDidMount() {
		if (this.state.loading && this.props.match.params.clienteId)
			this.refCliente = this.props.firebase.getCliente(this.props.match.params.clienteId);
			this.refCliente.once('value', snapshot => {
				this.setState(state => ({
					cliente: snapshot.val(),
					loading: false
				}), () => {
					this.nomeAntes = this.state.cliente.nome;
				});
			}
		);
	}


	addNovoTelefone(e) {
		const tamanho = this.state.cliente.telefones.length + 1;
		const novoTelefone = { desc: "Telefone " + tamanho.toString(), value: '' };

		let clientesUpdated = this.state.cliente;
		clientesUpdated.telefones = [...clientesUpdated.telefones, novoTelefone]

		//Concatena o novo telefone ao array this.state.telefones;
		this.setState(state => ({
			cliente: clientesUpdated
		}));

		e.preventDefault();
	}

	removerTelefone(e) {
		if (this.state.cliente.telefones.length <= 1)
			return;

		const telefoneRemovido = this.state.cliente.telefones.pop();
		let clientesUpdated = this.state.cliente;
		clientesUpdated.telefones = this.state.cliente.telefones.filter(t => t.desc !== telefoneRemovido.desc)

		this.setState(state => ({
			cliente: clientesUpdated
		}));

		e.preventDefault();
	}

	handleSubmit(e) {
		this.refCliente.update(this.state.cliente).then(() =>{
			this.props.firebase.getClientesLastUpd().once('value', snapshot =>{
				let ultimaAtt = Number(snapshot.val());
				this.props.firebase.getClientesLastUpd().set(ultimaAtt+1);
			}).then(alert(`"${this.state.cliente.nome}" foi atualizado com sucesso!`))

			if(this.nomeAntes !== this.state.nome)
				this.props.firebase.getClienteMin(this.props.location.keyClienteMin).update({nome:this.state.cliente.nome});
		})
		.catch(err => () => alert(`Erro ao atualizar "${this.state.cliente.nome}"!`));
		e.preventDefault();
	}

	handleTelefoneInput(e, id) {
		const newInput = this.state.cliente.telefones.slice();
		newInput[id].value = e.target.value;
		let clientesUpdated = this.state.cliente;

		clientesUpdated.telefones = newInput;

		this.setState(state => ({
			cliente: clientesUpdated
		}));

		e.preventDefault();
	}

	handleNomeInput(e) {
		let clientesUpdated = this.state.cliente;
		clientesUpdated.nome = e.target.value
		this.setState({ cliente: clientesUpdated });
		e.preventDefault();
	}

	render() {
		return (
			<AdminForm functions={this.pFunctions} cliente={this.state.cliente} page='home' loading={this.state.loading} >
				<h3 style={{ margin: "0 auto", marginTop: "2%", marginBottom: "4%", display: "table" }}>Atualizando o cliente "{this.state.cliente.nome}"</h3>
			</AdminForm>
		);
	}
}

export default compose(withRouter, withAuthentication)(ClienteUpdate);	