import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

/*
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};*/

const config = {
	apiKey: "AIzaSyAYLVFGrSYXXx60Ha1TgXi7ZKePsqIKOm0",
	authDomain: "agendafo-373ac.firebaseapp.com",
	databaseURL: "https://agendafo-373ac.firebaseio.com",
	projectId: "agendafo-373ac",
	storageBucket: "agendafo-373ac.appspot.com",
	messagingSenderId: "1010373118946"
};

class Firebase{
	constructor(){
		app.initializeApp(config);

		this.auth = app.auth();
		this.db   = app.database();
	}

	
	doLogin = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
	/*doLogin = (email, password, history) => this.auth.signInWithEmailAndPassword(email, password).then(() => {
		setTimeout( this.timeout = function(){ history.push(ROUTES.ADMIN) }, 1000);
		this.timeout=null;
	})
	.catch((err) => {
		clearTimeout(this.timeout);
		if(err.code === 'auth/user-not-found'){
			alert('Usuário não existente.');
			return;
		}else if (err.code === 'auth/wrong-password'){
			alert('Senha incorreta.');
			return;
		}else {
			alert(err.message);
			return;
		}
	});*/

	doSignOut = () => this.auth.signOut();

	getImagem = idImagem => this.db.ref(`Imagens/${idImagem}`);
	getImagens = () => this.db.ref('Imagens');
	getImagensLastUpd = () => this.db.ref('lastUpdateImagens');
	getSeqImagens = () => this.db.ref('seqImagens');

	getClientesMin = () => this.db.ref('ClientesMin');
	getClienteMin = idCliente => this.db.ref(`ClientesMin/${idCliente}`);

	
	getCliente = idCliente => this.db.ref(`Clientes/${idCliente}`);
	getClientes = () => this.db.ref('Clientes');
	getClientesLastUpd = () => this.db.ref('lastUpdateClientes');
	getSeqClientes = () => this.db.ref('seqClientes');

}

export default Firebase;