import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = (props) => {

    let handleCancelar = (e) => {
        props.handleCancelar();
        e.preventDefault();
    };

    let handleConfirmar = (e) => {
        props.handleConfirmar(props.cliente);
        e.preventDefault();
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleCancelar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Deletando <strong>{props.cliente.nome}</strong></DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Você realmente deseja deletar o cliente "<strong>{props.cliente.nome}</strong>"?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancelar} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleConfirmar} color="secondary" variant="contained" autoFocus>
                    Deletar
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AlertDialog;