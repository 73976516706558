import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Admin from '../components/Admin';
import AdminRight from '../components/AdminRight';
import CircularProgress from '@material-ui/core/CircularProgress';


class TelefoneInput extends React.PureComponent{
	constructor(props){
		super(props);
		this.state = {id:this.props.id, value:''};

		this.handleInput = this.handleInput.bind(this);
	}

	handleInput(e){
		this.setState({value:e.target.value})
		this.props.handleTelefoneInput(e, this.props.id);
		e.preventDefault();
	}

	render(){	
		return (<TextField
					label={this.props.Field.desc}
					value={this.props.Field.value}
					style={{ width:"90%"}}
					margin="normal"
					onChange={this.handleInput}
					required={this.props.id===0}
				/>  );
	}

}


const AdminForm = (props) => {

    return (
            <Admin doSignOut={props.functions.doLogout} page={props.page}>
                <AdminRight>
                {!props.loading?
                        <>
                            {props.children}
                            <Button onClick={props.functions.addNovoTelefone} 
                                    color="primary" 
                                    variant="outlined"
                                    disabled={props.functions.loading}
                                    style={{marginLeft:"10%"}}>
                                Adicionar novo telefone
                            </Button>

                            <Button onClick={props.functions.removerTelefone}
                                    style={{marginRight:"18%",float:"right"}}
                                    variant="contained"
                                    color="secondary" 
                                    disabled={props.functions.loading}
                                    className={props.button}>
                                Remover telefone
                            </Button>

                            <form onSubmit={props.functions.handleSubmit}>
                                <div className="text-inputs">
                                    <TextField label="Nome" id="nome" value={props.cliente.nome} autoFocus={true} className="text_inp" onChange={props.functions.hadleNomeInput} margin="normal" required/>
                                    {props.cliente.telefones.map( (__telefone,__index) => 
                                        <TelefoneInput Field={__telefone} key={__index.toString()} id={__index} handleTelefoneInput={props.functions.handleTelefoneInput} />)
                                    }
                                </div>

                                <Button variant="contained" 
                                        type="submit" 
                                        color="primary" 
                                        style={{marginBottom:"3%", marginRight:"18%",float:"right"}}
                                        disabled={props.loading}
                                        className={props.button}>
                                    Enviar
                                </Button>
                            </form>
                        </>
                        :   <CircularProgress className={props.progress} size={60} style={{margin:"0 auto", marginTop:"5%",display:"table"}}/>
                        
                    }
               
                </AdminRight>
            </Admin>
    );
}

export default AdminForm;


