import React from 'react';
import '../Admin.css';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import withAuthentication from '../../Session/withAuthentication';
import AdminRight from '../components/AdminRight';
import Admin from '../components/Admin';



class ImagemView extends React.PureComponent{
	
	handleUpdate = e => this.props.atualizaImagem(this.props.id, e.target.files[0]);
	
	handleRemove = () => this.props.removerImagem(this.props.id);
	
	render(){	
		//let desc = 'Imagem ' + this.props.id;
		let idImagem = 'imagem'+this.props.id;
		let img = 'data:image/png;base64, ' + this.props.imagem.toString();

		return (<div style={{background: "#8CCF88"}}>
				<div style={{marginLeft:"2%", marginBottom:"4%", background: "#FFF"}}>
					<div style={{marginBottom:"2%", marginLeft:"5%", paddingTop:"2%"}}>
						<input id={idImagem} accept="image/*" className={this.props.input} type="file" hidden onChange={this.handleUpdate}/>
						<label htmlFor={idImagem}>
							<Button variant="contained" color="primary" component="span" className={this.props.button} style={{marginRight:"2%"}}>
								Atualizar Imagem
							</Button>
						</label>

						<Button variant="contained" 
								color="secondary" 
								className={this.props.button}
								onClick={this.handleRemove}>
							Apagar Imagem
							<DeleteIcon className={this.props.rightIcon} />
						</Button>
						<Divider variant="inset" style={{width:"95%", marginTop:"1%"}} />
					</div>
					<img style={{paddingBottom:"2%",marginLeft:"5%"}} src={img} alt={this.props.descImagem} />
				</div>
				</div> );
	}
}


class AdminImagens extends React.Component{
	constructor(props){
		super(props);

		this.state = {
			imagens: [],
			loading: false,
			loadingEnvio: false,
			lastSeq: 0
		}
	};

	getUid = (pid) => {
		for(var i=0; i < this.state.imagens.length; i++){
			if(this.state.imagens[i].uid === pid)
				return i;
		}
		return -1;
	}

	atualizaImagem = (pid, file) => {
		let reader  = new FileReader();
		let imagemCarregada;
		if(file){
			reader.readAsDataURL(file);
			reader.onload = e => { 
				imagemCarregada = e.target.result.split(',')[1]; 

				let id = this.getUid(pid);
				if(id === -1){
					console.log('Epa nenem');
					return;
				}
			
				let imagensAtualizadas = this.state.imagens;
				imagensAtualizadas[id].src = imagemCarregada;

				this.setState(state => ({
					imagens: imagensAtualizadas,
				}));
			}
		}
	}
	
	componentDidMount(){
		if( !this.state.loading ){
			this.setState(state => ({ loading: true}) );
			this.props.firebase.getImagens().once('value', snapshot =>{
				let imgObj = snapshot.val();
				let lastSeq = 0;

				let img = imgObj;
			
				/*	let img = Object.keys(imgObj).map(key => ({
					...imgObj[key],
					uid: Number(key),
				  }));*/
				
				this.props.firebase.getSeqImagens().once('value', snapshot =>{
					lastSeq = Number(snapshot.val());
					this.setState(state => ({ loading:false, imagens:img, lastSeq:lastSeq }));
				});
				
			});
		}		
	}

	removerImagem = id => {
		this.setState({
		  imagens: this.state.imagens.filter(image => image.uid !== id)
		})
	}

	atualizarRegistros = () => {
		this.setState({loadingEnvio:true});

		this.props.firebase.getImagens().set(this.state.imagens).then(() => {
			this.props.firebase.getImagensLastUpd().once('value', snapshot =>{
				let ultimaAtt = Number(snapshot.val());
				this.props.firebase.getImagensLastUpd().set(ultimaAtt+1);
				this.props.firebase.getSeqImagens().set(this.state.lastSeq);
				this.setState({loadingEnvio:false});
			}).then(alert("Imagens atualizadas com sucesso!!"))
		}).catch(() => alert("Erro ao atualizar as imagens!"));
	}
	
	addNovaImagem = () => {
		this.setState(state => ({
			lastSeq: state.lastSeq + 1,
		}), this.setState(state => ({
			imagens: [...state.imagens, {"src":"", uid:this.state.lastSeq} ],
		})), window.scrollTo(0,document.body.scrollHeight));
		
	}


	render(){
		let Imagens = this.state.imagens.map((_imagem) => <div className="right_body_img" key={_imagem.uid}> <ImagemView imagem={_imagem.src} id={_imagem.uid} key={_imagem.uid} removerImagem={this.removerImagem} atualizaImagem={this.atualizaImagem} /> </div>);
		let body;

		if(!this.state.loading){
			body =	<>
						<Button variant="outlined" 
								color="primary" 
								className={this.props.button}
								onClick={this.addNovaImagem}
								style={{ margin:"0 auto", marginBottom:"1%", width:"60%", display:"table"}}>
							Adicionar nova imagem
						</Button>
						<Button variant="contained" 
								color="primary" 
								disabled={this.state.loadingEnvio}
								className={this.props.button}
								onClick={this.atualizarRegistros}
								style={{ margin:"0 auto", marginBottom:"4%", width:"60%", display:"table"}}>
							Confirmar Alterações
						</Button>
						{Imagens}
						<Button variant="contained" 
								color="primary" 
								disabled={this.state.loadingEnvio}
								className={this.props.button}
								onClick={this.atualizarRegistros}
								style={{ margin:"0 auto", marginBottom:"2%", width:"60%", display:"table"}}>
							Confirmar Alterações
						</Button>
					</>
		}else{
			body =	<CircularProgress className={this.props.progress} size={80} style={{margin:"0 auto",display:"table"}}/>
					
		}
		
		
		return (<Admin doSignOut={this.props.firebase.doSignOut} page='imagem'>
					<AdminRight>
						{body}
					</AdminRight>
				</Admin>);
	}

}

export default withAuthentication(AdminImagens);