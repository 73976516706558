import React from 'react';

const AdminRight = (props) => {
    return(
            <div className="right">
				<div id="right_body" className="right_body">
                    {props.children}
                </div>
			</div>	
    );
}

export default AdminRight;