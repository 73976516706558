import React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const AdminNav = (props) => {
    return(
        <div className="left">
            <div className="left_header">Administração Agenda AFO</div>
            <div className="left_body">
                <p className="left_sidebar" style={{fontWeight:props.page==='home'  ? "bold" : "normal"}}><NavLink className="side_nav" exact to={ROUTES.ADMIN_HOME}>Cadastro de Clientes</NavLink></p>
                <p className="left_sidebar" style={{fontWeight:props.page==='add'   ? "bold" : "normal"}}><NavLink className="side_nav" exact to={ROUTES.ADMIN_ADD}>Adicionar Contato</NavLink></p>
                <p className="left_sidebar" style={{fontWeight:props.page==='imagem'? "bold" : "normal"}}><NavLink className="side_nav" exact to={ROUTES.ADMIN_IMAGENS}>Alterar Imagens</NavLink></p>
                <div className="left_sidebar" onClick={props.doLogout }><p className="side_nav" style={{color:"red", fontWeight:"bold"}}>Sair</p></div>
            </div>
        </div>
    );

}

export default AdminNav;